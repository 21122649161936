<template>
  <div>
    <div>
      <div class="Index">
        <div class="headerBg cearcone">
          <!-- end -->
          <div class="bottom">
            <div class="bottLeft">
              <!-- 显示 -->
              <div class="leftBottom" v-show="money">
                {{ infoArr.balance }}
                <i
                  class="iconfont icon-xianshi iconFont"
                  @click="moneyShow"
                ></i>
              </div>
              <!-- 隐藏 -->
              <div class="leftBottom" v-show="!money">
                <span class="iojef">*******</span>
                <img
                  class="iconFont iconFont1"
                  src="../../src/assets/icon/monyed.png"
                  alt=""
                  @click="moneyShow"
                />
              </div>
              <div class="text">可提现收入(元)</div>
              <div class="text text1">
                <i class="iconfont icon-icon-question"></i> 提现规则
              </div>
            </div>
            <div class="bottRight">
              <van-button
                round
                type="default"
                size="small"
                @click="sucnscc"
                ><span class="colorfga">提现</span></van-button
              >
            </div>
          </div>
        </div>
      </div>
      <!-- end -->
      <van-tabs
        v-model="active"
        animated
        sticky
        color="#0E8AFFFF"
        @click="onClick"
      >
        <van-tab title="收入明细" name="1">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              :immediate-check="false"
              @load="onLoad"
            >
              <div class="ul" v-if="listData">
                <div
                  class="li"
                  v-for="(item1, index1) in listData"
                  :key="index1"
                >
                  <template>
                    <div class="left">
                      <h4 class="leftTitle">{{ item1.cash_type_name }}</h4>
                      <p class="fontSize">流水ID:{{ item1.order_sn }}</p>
                      <p class="teltiel">{{ item1.add_time }}</p>
                    </div>
                    <div class="right">
                      <p class="typeMonye">{{ item1.amount | numFilter }}</p>
                      <p class="meotile">{{ item1.operator_center }}</p>
                    </div>
                  </template>
                </div>
              </div>

              <div class="blank" v-else>
                <van-empty description="暂无数据" />
              </div>
            </van-list>
          </van-pull-refresh>
        </van-tab>
        <van-tab title="提现明细" name="2">
          <van-pull-refresh v-model="refreshing1" @refresh="onRefresh1">
            <van-list
              v-model="loading1"
              :finished="finished1"
              finished-text="没有更多了"
              :immediate-check="false"
              @load="onLoad1"
            >
              <div class="ul" v-if="listData1">
                <div
                  class="li"
                  v-for="(item1, index1) in listData1"
                  :key="index1"
                >
                  <!-- end提现 -->
                  <template>
                    <div class="left" @click="Jump(item1.id)">
                      <h4 class="numbersOdd">提现单号{{ item1.order_sn }}</h4>
                      <p
                        class="fontSize fontSize1"
                        v-if="item1.payment == 'paybank'"
                      >
                        提现方式:银行卡
                      </p>
                      <p
                        class="fontSize fontSize1"
                        v-else-if="item1.payment == 'transfer'"
                      >
                        提现方式:微信零钱
                      </p>
                      <p class="teltiel">{{ item1.add_time }}</p>
                    </div>
                    <div class="right" @click="Jump(item1.id)">
                      <p class="typesize" v-if="item1.state_name == '待审核'">
                        待审核
                      </p>
                      <p
                        class="typesize typesize1"
                        v-else-if="item1.state_name == '已到账'"
                      >
                        已到账
                      </p>
                      <p
                        class="typesize typesize2"
                        v-else-if="item1.state_name == '申请中'"
                      >
                        申请中
                      </p>
                      <p
                        class="typesize typesize2"
                        v-else-if="item1.state_name == '发放中'"
                      >
                        发放中
                      </p>
                      <p class="typesize1" v-else>{{ item1.state_name }}</p>
                      <p class="typeMonye">{{ item1.amount | numFilter }}</p>
                    </div>
                  </template>
                </div>
              </div>
              <div class="blank" v-else>
                <van-empty description="暂无数据" />
              </div>
            </van-list>
          </van-pull-refresh>
        </van-tab>
      </van-tabs>
    </div>
    <!-- end提现失败 -->
    <div class="popupE" v-show="errorShow"></div>
    <div
      class="successBox successBox1 animate__animated animate__shakeY"
      v-show="errorShow"
    >
      <div>
        <div class="textTel textTel1">
          <van-button type="info" class="seanns" size="normal" @click="identification"
            >去设置</van-button
          >
        </div>
      </div>
      <van-icon name="cross" class="icondace" @click="errorShow = false" />
    </div>
    <van-popup
      v-model="identificationShow"
      :style="{ height: '40%', width: '90%' }"
      :close-on-click-overlay="false"
    >
      <van-icon name="cross" class="floatright" @click="identificationShow = false" />
      <Investment></Investment>
    </van-popup>
  </div>
</template>

<script>
import { incomeDetail, withdrawalsRecord } from "@/request/api";
import { setItem, getItem } from "@/utils/storage";
import { mapState } from "vuex";
import { Notify } from "vant";
import Investment from "@/components/Authentication/Authentication"
export default {
    components:{
    Investment
  },
  data() {
    return {
      money: getItem("money"),
      active: 0,
      successShow: false, //提现成功显示
      errorShow: false, //提现失败显示
      listData: [], //收入明细
      listData1: [], //提现明细
      page: {
        page: "1",
        size: "5",
      },
      identificationShow:false,
      refreshing: false,
      refreshing1: false,
      finished: false,
      loading: false,
      loading1: false,
      finished1: false,
    };
  },
  computed: {
    ...mapState(["infoArr"]),
  },
  mounted() {
    this.getData();
  },
  filters: {
    numFilter(value) {
      let realVal = "";
      if (!isNaN(value) && value !== "") {
        // 截取当前数据到小数点后三位
        let tempVal = parseFloat(value).toFixed(3);
        realVal = tempVal.substring(0, tempVal.length - 1);
      } else {
        realVal = "--";
      }
      return realVal;
    },
  },
  methods: {
    moneyShow() {
      this.money = !this.money;
      setItem("money", this.money);
    },
    // 提现按钮 根据接口返回提示失败或成功
    withdrawalClick() {},
    withdrawaUrl(e) {
      this.$router.push({ path: "/Withdrawalparticulars", query: { id: e } });
    },
    getData() {
      incomeDetail(this.page).then((res) => {
        this.listData = res.data.data;
      });
    },
    onClick(name) {
      if (name == 1) {
        this.page.page = "1";
        this.getData();
        this.finished = false;
        this.loading = false;
      } else if (name == 2) {
        this.page.page = "1";
        this.withdrawalGet();
        this.finished1 = false;
        this.loading1 = false;
      }
    },
    // 提现
    withdrawalGet() {
      withdrawalsRecord(this.page).then((res) => {
        this.listData1 = res.data.data;
      });
    },
    // 下拉刷新
    onRefresh() {
      setTimeout(() => {
        this.refreshing = false;
        this.page.page = "1";
        this.finished = false;
        this.loading = false;
        this.getData();
        Notify({ type: "success", message: "刷新成功" });
      }, 1000);
    },
    // 提现下拉刷新
    onRefresh1() {
      setTimeout(() => {
        this.refreshing1 = false;
        this.page.page = "1";
        // this.finished = false;
        // this.loading = false;
        this.withdrawalGet();
        Notify({ type: "success", message: "刷新成功" });
      }, 1000);
    },
    onLoad() {
      setTimeout(() => {
        this.page.page++;
        this.getDatanum();
      }, 1000);
    },
    onLoad1(){
      // console.log('下拉了')
      setTimeout(() => {
        this.page.page++;
        this.getDatanum1();
      }, 1000);
    },
     getDatanum1() {
      withdrawalsRecord(this.page).then((res) => {
        if (res.code == 1005) {
          this.finished1 = true;
          return;
        }
        this.listData1 = this.listData1.concat(res.data.data);
        this.loading1 = false;
      });
    },
    getDatanum() {
      incomeDetail(this.page).then((res) => {
        if (res.code == 1005) {
          this.finished = true;
          return;
        }
        this.listData = this.listData.concat(res.data.data);
        this.loading = false;
      });
    },
    Jump(userId) {
      this.$router.push({
        path: "/Withdrawalparticulars",
        query: { id: userId },
      });
    },
     identification(){
      this.errorShow = false;
      this.identificationShow = true;
    },
      //判断是否认证了
   sucnscc(){
      if(this.infoArr.id_card==null||this.infoArr.id_card==''&&this.infoArr.truename==null||this.infoArr.truename==''){
        // console.log('1')
        this.errorShow = true;
        let path = this.$router.history.current.path;
        this.$router.push({ path, query: {redirect:'/CashOut'}});
      } else{
        this.$router.push('/CashOut');
      }
    },
  },
};
</script>

<style scoped>
.app-box {
  min-height: 100vh;
  box-sizing: border-box;
  margin-bottom: 50px;
}
.Index {
  background: url(../../src/assets/img/head_bg.jpg) no-repeat;
  background-size: 100% 100%;
}
.cearcone {
  padding: 15px 10px;
  box-sizing: border-box;
}
.headerBg {
  width: 100%;
  overflow: hidden;

  /* background: red; */
  background-size: 100% 100%;
}
.headerBg .top {
  overflow: hidden;
  position: relative;
}
.headerBg .left {
  border-radius: 50%;
  overflow: hidden;
  width: 45px;
  height: 45px;
  float: left;
}
.headerBg .left img {
  width: 100%;
}
.headerBg .right {
  margin-left: 50px;
  color: #fff;
}
.authentication {
  font-size: 10px;
  background: #6782f2ff;
  padding: 3px 4px;
  border-radius: 3px;
  margin-left: 5px;
}
.titleg {
  font-size: 16px;
  margin-bottom: 3px;
}
.tmUser {
  font-size: 15px;
}
.setIcom {
  width: 20px;
  height: 20px;
  background: url(../../src/assets/icon/setUp.png) no-repeat;
  background-size: 100%;
  font-size: 20px;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
}
.bottom {
  margin-top: 20px;
  display: flex;
}
.bottom .bottLeft {
  flex: 3;
}
.bottom .bottRight {
  flex: 2;
  text-align: right;
}
.bottom .text {
  font-size: 15px;
  color: #fff;
}
.bottom .leftBottom {
  font-size: 30px;
  color: #fff;
  position: relative;
}
.text1 {
  margin-top: 20px;
}
.leftBottom .iconFont {
  font-size: 32px;
  margin-top: 0.1rem;
}
.bottRight .van-button {
  padding: 0 30px;
  margin-top: 10px;
}
.typeBox {
  padding: 10px 10px;
  box-sizing: border-box;
  display: flex;
}
.typeBox .li {
  flex: 1;
  border-radius: 10px;
  padding: 13px 10px;
  padding-right: 5px;
}
.typeBox .li .title {
  font-size: 16px;
}
.typeBox .li .iconLi {
  font-size: 20px;
  color: #8c8ea0ff;
  vertical-align: middle;
  float: right;
}
.rightAu {
  margin-left: 10px;
}
.li .textNnm {
  font-size: 25px;
  font-weight: bold;
  margin-top: 8px;
}
.colorfga {
  color: #4163eeff;
  font-size: 16px;
}
.typeBoxImg {
  padding: 0 10px;
}
.typeBoxImg .li {
  height: 210px;
  overflow: hidden;
}
.typeBoxImg .leftImg {
  background: url(../../src/assets/img/left.png) no-repeat;
  background-size: 100% 100%;
}
.typeBoxImg .rightImg {
  background: url(../../src/assets/img/right.png) no-repeat;
  background-size: 100% 100%;
}
.imgicom1 {
  background: url(../../src/assets/img/imfkle.png) no-repeat;
  background-size: 100%;
}
.imgicom2 {
  background: url(../../src/assets/img/rimrile.png) no-repeat;
  background-size: 100%;
}
.trainBox {
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
}
.trainUl {
  background: #fff;
}
.trainUl {
  padding: 5px 10px;
  border-radius: 10px;
}
.trainUl h4::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 20px;
  background: #4163eeff;
  vertical-align: bottom;
  margin-right: 5px;
  border-radius: 3px;
}
.trainUl .banBg {
  width: 100%;
  padding: 5px 0;
}
.iconFont1 {
  display: inline;
  width: 20px;
  height: 20px;
  position: absolute;
}
.iojef {
  margin-right: 5px;
}
.cearcone {
  margin-bottom: 5px;
  box-shadow: 10px 1px 9px #ccc;
}
::v-deep .van-tabs__wrap {
  border-bottom: 1px solid #d0d2daff;
}
::v-deep .van-tab--active {
  color: #0e8affff;
}
.ul .li {
  display: flex;
  padding: 10px 0;
  width: 95%;
  margin: auto;
  box-sizing: border-box;
  border-bottom: 1px solid #f3f3f5ff;
}
.ul .li .left {
  flex: 3;
}
.ul .li .left p,
.ul .li .left h4,
.ul .li .right p {
  margin-bottom: 3px;
}
.ul .li .right {
  flex: 2;
  text-align: right;
}
.teltiel {
  color: #83889cff;
  font-size: .35rem;
}
.li .fontSize {
  font-size: 0.4rem;
}
.right .typesize {
  font-size: .38rem;
  color: #0ec13eff;
}
.right .typeMonye {
  color: #ff2c00ff;
  margin-top: 2px;
  font-size: .35rem;
}
.meotile {
  color: #4c5370ff;
  font-size: .4rem;
}
.typesize1 {
  color: #000 !important;
  font-size: .38rem;

}
.typesize2 {
  font-size: .38rem;
  color: #0e8affff !important;
}
.popupE {
  width: 100%;
  height: 100vh;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
}
.successBox {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 270px;
  height: 273px;
  transform: translate(-50%, -50%);
  z-index: 101;
  background: url(../assets/img/withdrawalscor.png);
  background-size: 100% 100%;
}
.textTel {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 35px;
}
.textTel1 {
  bottom: 15px;
}
.icondace {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  bottom: -50px;
  transform: translate(-50%, 0);
  font-size: 20px;
}
.successBox1 {
  background: url(../assets/img/error.png);
  background-size: 100% 100%;
  left: 15%;
    top: 30%;
}
.textTel1 ::v-deep .seanns {
  border-radius: 10px;
  padding: 0 70px;
}
.typesize2 {
  color: #0e8affff !important;
}
.numbersOdd {
  font-size: 0.35rem;
}
.fontSize1 {
  font-size: 0.35rem !important;
}
::v-deep .van-nav-bar .van-icon {
  color: #000;
}
.floatright{
  position: absolute;
  top: 10px;
  right: 10px;
}
.leftTitle{
  font-size: .43rem;
}
</style>